<template>
    <b-row>
        <b-col :cols="12">
            <b-table striped hover responsive :items="subscriptionHistoriesMapped" :fields="subscriptionColumns">
                <template #cell(action)="{item}">
                    <template v-if="!isEmpty(item.payment_details)">
                        <b-img :src="$helpers.getIcons('view')" fluid width="16" @click="viewPaymentData(item.payment_details)" />
                    </template>
                </template>
            </b-table>
        </b-col>
        <b-modal v-model="paymentModal" centered title="Payment Details">
            <template v-if="!isEmpty(paymentData)">
                <b-row class="mt-1" v-for="(value, key) in paymentData" :key="`pd_${key}`">
                    <b-col cols="6" md="4" class="text-right text-uppercase font-weight-bold pr-0 mr-0 text-muted">{{ key
                    }}: </b-col>
                    <b-col cols="6" md="8" class="text-left pl-0 ml-0">{{ value }}</b-col>
                </b-row>

            </template>
        </b-modal>
    </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { dateTimeFormat, isEmpty } from '@/utilities'
const subscriptionColumns = [
    {
        key: 'plan_name',
        label: 'Plan',
        sortable: false,
    },
    {
        key: 'plan_amount',
        label: 'Amount',
        sortable: false,
    },
    {
        key: 'start_date',
        label: 'Start Date',
        sortable: false,
    },
    {
        key: 'end_date',
        label: 'End Date',
        sortable: false,
    },
    {
        key: 'status_txt',
        label: 'Subscription Status',
        sortable: false,
    },
    {
        key: 'created_at',
        label: 'Created On',
        sortable: false,
    },
    {
        key: 'action',
        label: 'Payment Details',
        sortable: false,
    },
]
export default {
    name: "",
    props: {
        subscriptionHistories: Array
    },
    data: () => ({
        subscriptionColumns,
        paymentModal: false,
        paymentData: {}
    }),
    computed: {
        ...mapGetters('subscriptionPlan', [
            'getSubscription'
        ]),
        subscriptionHistoriesMapped: {
            get(){
                return this.subscriptionHistories.map(sub => {
                    const plan = this.getSubscription(sub.sp_uuid)
                    if(isEmpty(plan)) return false
                    const isActive = sub.subscription_status == 1 ? true : false
                    const subStatus = parseInt(sub.subscription_status)
                    let status_txt;
                    switch (subStatus) {
                        case 1:
                            status_txt = 'Active'
                            break;
                        case 2:
                            status_txt = 'Cancelled'
                            break;
                        case 3:
                            status_txt = 'Upcoming'
                            break;
                        case 4:
                            status_txt = 'Cancellation Processing'
                            break;
                        default:
                            break;
                    }
                    return {
                        ...sub,
                        plan,
                        plan_name: plan?.package_name,
                        plan_amount: plan?.package_amount,
                        status_txt,
                        start_date: dateTimeFormat(sub.start_date, 'DD MMM, YYYY'),
                        end_date: isEmpty(sub.end_date) ? 'Lifetime' : dateTimeFormat(sub.end_date, 'DD MMM, YYYY'),
                        created_at: isEmpty(sub.created_at) ? 'N/A' : dateTimeFormat(sub.created_at, 'DD MMM, YYYY'),
                        isActive
                    }
                }).filter(i => !isEmpty(i))
            }
        }
    },
    methods:{
        isEmpty,
        viewPaymentData(payment_details){
            this.paymentData = JSON.parse(payment_details)
            this.paymentModal = true
        }
    }
}
</script>

<style>

</style>